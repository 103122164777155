@import " ~react-perfect-scrollbar/dist/css/styles.css";

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Degular";
  src: url("./font/Degular-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  */
.slick-track {
  display: flex !important;
  align-items: center !important;
}